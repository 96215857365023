function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

$(document).ready(function () {
    const cookieSeen = getCookie('cookieSeen');
    const cookieBanner = $('#cookie-banner');

    if (cookieSeen != "") {
        cookieBanner.hide()
        return;
    }

    cookieBanner.show()
    $('#accept-cookies').on('click', function(e) {
        e.preventDefault();
        setCookie('cookieSeen', 1, 30);
        cookieBanner.hide()

        const event = new Event('cookie-banner-close');
        const eventCookieBanner = document.getElementById('cookie-banner')
        if(eventCookieBanner) eventCookieBanner.dispatchEvent(event);
    })
})
